<template>
    <div class="app-container">

        <el-row :gutter="10" class="mb8">
            <el-col :span="1.5">
                <el-button type="primary" plain icon="el-icon-plus" size="mini" @click="handleAdd"
                    v-hasPermi="['base:catalog:add']">新增</el-button>
            </el-col>
            <el-col :span="1.5">
                <el-button type="success" plain icon="el-icon-edit" size="mini" :disabled="single" @click="handleUpdate"
                    v-hasPermi="['base:catalog:edit']">修改
                </el-button>
            </el-col>
            <el-col :span="1.5">
                <el-button type="danger" plain icon="el-icon-delete" size="mini" :disabled="multiple" @click="handleDelete"
                    v-hasPermi="['base:catalog:delete']">删除
                </el-button>
            </el-col>

            <right-toolbar style="margin-right: 10px;" :showSearchIcon="false" @queryTable="getList"></right-toolbar>
        </el-row>

        <el-table :loading="loading" :data="baseCatalogList" @selection-change="handleSelectionChange" row-key="id"
            :tree-props="{ children: 'children' }">
            <el-table-column type="selection" width="55" align="center" />
            <el-table-column label="分类名称" prop="name" />
            <el-table-column label="分类图标" align="center" prop="icon">
                <template v-slot="slot">
                    <div v-if="slot.row.icon == null || slot.row.icon == ''">未上传图标</div>
                    <img :src="slot.row.icon" width="24px" alt="image error" v-else>
                </template>
            </el-table-column>
            <el-table-column label="排序" align="center" prop="sort" />
            <el-table-column label="状态" align="center" prop="status">
                <template #default='scope'>
                    <el-tag :type="statusDict[scope.row.status].color">{{ statusDict[scope.row.status].label }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
                <template slot-scope="scope">
                    <el-button size="mini" type="text" icon="el-icon-edit" @click="handleUpdate(scope.row)"
                        v-hasPermi="['base:catalog:edit']">修改
                    </el-button>
                    <el-button size="mini" type="text" icon="el-icon-delete" @click="handleDelete(scope.row)"
                        v-hasPermi="['base:catalog:delete']">删除
                    </el-button>
                    <el-button v-if="false" size="mini" type="text" icon="el-icon-plus" @click="addChildren(scope.row)"
                        v-hasPermi="['base:catalog:add']">添加子分类
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <!-- 添加或修改商品分类对话框 -->
        <el-dialog :title="title" :visible.sync="open" width="650px" append-to-body>
            <el-form ref="form" :model="form" :rules="rules" label-width="130px">
                <el-form-item label="上级分类" prop="parentName" v-if="form.id == null">
                    <div>{{ form.parentName }}</div>
                </el-form-item>
                <el-form-item label="上级分类" prop="deptId" v-else>
                    <treeselect v-model="form.parentId" :options="treeSelectList" :show-count="true"
                        :normalizer="normalizer" />
                </el-form-item>
                <el-form-item label="分类名称" prop="name">
                    <el-input v-model="form.name" placeholder="请输入分类名称" />
                </el-form-item>
                <el-form-item label="分类图标" prop="icon">
                    <imageUpload v-model="form.icon" :limit="parseInt(1)" />
                </el-form-item>
                <el-form-item label="排序" prop="sort">
                    <el-input v-model.number="form.sort" type="number" placeholder="请输入排序" />
                </el-form-item>
                <el-form-item label="状态" prop="status">
                    <el-radio-group v-model="form.status">
                        <el-radio label="0">正常</el-radio>
                        <el-radio label="1">停用</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>
  
<script>
import { getToken } from "@/utils/auth";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import api from '@/api/commonApi'

export default {
    name: "BaseCatalog",
    components: { Treeselect },
    data() {
        return {
            uploadUrl: process.env.VUE_APP_SERVER_URL + "/api/common/upload", // 上传的图片服务器地址
            headers: {
                token: getToken()
            },
            // 遮罩层
            loading: true,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            // showSearch: true,
            // 总条数
            // total: 0,
            // 商品分类表格数据
            baseCatalogList: [],
            // 树选择器列表数据
            treeSelectList: [],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 查询参数
            queryParams: {
                pageNum: 1,
                pageSize: 10,
                name: null,
                status: null,
            },
            // 表单参数
            form: {},
            // 表单校验
            rules: {
                name: [
                    { required: true, message: '请输入分类名称', trigger: 'blur' },
                    { min: 1, max: 10, message: '长度在 1 到 10 个字符', trigger: 'blur' }
                ],
                sort: [
                    { type: 'number', required: true, message: '请输入排序号', trigger: 'blur' }
                ],
                commission: [
                    { type: 'number', min: 0, max: 100, required: true, message: '请正确输入佣金比例（0~100）', trigger: 'blur' }
                ],
            },
            normalizer(node) {
                return {
                    id: node.id,
                    label: node.name,
                    children: node.children,
                }
            },
            statusDict: {
                '0': { color: 'success', label: '启用' },
                '1': { color: 'danger', label: '停用' },
            }
        };
    },
    created() {
        this.getList();
    },
    methods: {
        /** 查询商品分类列表 */
        getList() {
            this.loading = true;
            api.catalogList(this.queryParams).then(response => {
                console.log(response);
                this.baseCatalogList = response;
                this.treeSelectList = Object.assign([], response);
                this.treeSelectList.unshift({ id: 0, name: "顶级分类" });
                this.loading = false;
            });
        },
        // 取消按钮
        cancel() {
            this.open = false;
            // 取消禁选此分类
            if (this.form.id != null) {
                this.handleState(this.treeSelectList, this.form.id, false);
            }
            this.reset();
        },
        // 表单重置
        reset() {
            this.form = {
                id: null,
                name: null,
                icon: null,
                sort: 0,
                commission: 0,
                status: "0",
                parentId: null,
                parentName: null,
                catLevel: null
            };
        },
        // /** 搜索按钮操作 */
        // handleQuery() {
        //   this.queryParams.pageNum = 1;
        //   this.getList();
        // },
        // /** 重置按钮操作 */
        // resetQuery() {
        //   this.resetForm("queryForm");
        //   this.handleQuery();
        // },
        // 多选框选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map(item => item.id)
            this.single = selection.length !== 1
            this.multiple = !selection.length
        },
        /** 新增按钮操作 */
        handleAdd() {
            this.reset();
            this.form.parentId = 0;
            this.form.parentName = "顶级分类";
            this.title = "添加商品分类";
            this.open = true;
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.reset();
            const id = row.id || this.ids
            // 禁选自己
            this.handleState(this.treeSelectList, id, true);
            api.getCatalog(id).then(response => {
                this.form = response;
                this.open = true;
                this.title = "修改商品分类";
            });
        },
        /** 处理是否禁选 递归 */
        handleState(list, id, flg) {
            for (let i = 0; i < list.length; i++) {
                let item = list[i];
                if (item.id == id) {
                    item.isDisabled = flg;
                    break;
                } else if (item.children != null && item.children.length > 0) {
                    this.handleState(item.children, id, flg);
                }
            }
        },
        /** 添加子分类 */
        addChildren(row) {
            this.reset();
            this.form.parentId = row.id;
            this.form.parentName = row.name;
            if (row.catLevel == null) {
                this.form.catLevel = row.id;
            } else {
                this.form.catLevel = row.catLevel + "," + row.id;
            }
            this.title = "添加商品分类";
            this.open = true;
        },
        /** 提交按钮 */
        submitForm() {
            this.$refs["form"].validate(valid => {
                if (valid) {
                    if (this.form.id != null) {
                        api.editCatalog(this.form).then(response => {
                            this.$message.success("修改成功");
                            this.open = false;
                            this.getList();
                        });
                    } else {
                        api.addCatalog(this.form).then(response => {
                            this.$message.success("新增成功");
                            this.open = false;
                            this.getList();
                        });
                    }
                }
            });
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            const ids = row.id || this.ids;
            this.$confirm('是否确认删除商品分类编号为"' + ids + '"的数据项？').then(function () {
                return api.delCatalog({ids: ids + ''});
            }).then(() => {
                this.getList();
                this.$message.success("删除成功");
            }).catch(() => {
            });
        },
        /** 上传成功回调方法 */
        uploadSuccess(res, file) {
            if (res.code == 200) {
                this.form.icon = res.url;
            } else {
                this.$message.error("图片插入失败");
            }
        },
        /** 图标上传前 */
        uploadBefore(file) {
            // 校验是否图片
            const notImg = 'image/jpeg,image/png,image/jpg,image/bmp,image/webp'.indexOf(file.type) < 0;
            if (notImg) {
                this.$message.error(`请上传图片类型的文件`);
                return false;
            }
            // 校检文件大小
            if (file.size / 1024 / 1024 > 2) {
                this.$message.error(`上传图标大小不能超过 2 MB!`);
                return false;
            }
            return true;
        },
        uploadError() {
            this.$message.error("图片插入失败");
        },
    }
};
</script>
<style lang="scss" scoped>
.icon-upload {

    ::v-deep .el-upload {
        border: 1px dashed #D9D9D9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    ::v-deep .el-upload:hover {
        border-color: #409EFF;
    }

    .icon-uploader-icon {
        font-size: 28px;
        color: #8C939D;
        width: 64px;
        height: 64px;
        line-height: 64px;
        text-align: center;
    }

    .img-icon {
        height: 64px;
        width: 64px;
        display: block;
    }
}
</style>
  